<!-- DescriptionForm.svelte -->
<script lang="ts">
	import { onMount, onDestroy } from 'svelte'
	import StarterKit from '@tiptap/starter-kit'
	import Placeholder from '@tiptap/extension-placeholder'
	import Underline from '@tiptap/extension-underline'
	import TextStyle from '@tiptap/extension-text-style'
	import DOMPurify from 'dompurify'
	import { browser } from '$app/environment'
	import SkeletonBlock from '$lib/components/loading/SkeletonBlock.svelte'
	import SleekButton from '../buttons/SleekButton.svelte'
	import { Editor } from '@tiptap/core'

	interface Props {
		initialContent?: string
		placeholder?: string
		onchange: (content: string, error: boolean) => void
	}

	let {
		initialContent = 'Your text here...',
		placeholder = 'Write your description here...',
		onchange,
	}: Props = $props()

	let error = $state(null)
	let editor: Editor = $state()
	let element: HTMLDivElement = $state()

	function validateDescription(plainText: string) {
		if (plainText.length < 3) return 'Must be at least 3 characters long.'
		if (plainText.length > 2000) return 'Must be less than 2000 characters long.'
		return ''
	}

	function handleEditorChange(editor: Editor) {
		const rawHTML = editor.getHTML()
		const sanitizedHTML = DOMPurify.sanitize(rawHTML)?.trim()
		const plainText =
			DOMPurify.sanitize(rawHTML, { ALLOWED_TAGS: [], KEEP_CONTENT: true })?.trim() ?? ''

		error = validateDescription(plainText)

		onchange(sanitizedHTML, error !== '')
	}

	onMount(() => {
		editor = new Editor({
			element,
			extensions: [
				StarterKit.configure({
					heading: {
						levels: [1, 2, 3],
					},
				}),
				Placeholder.configure({
					placeholder: placeholder,
				}),
				Underline,
				TextStyle,
			],
			content:
				initialContent != '' && initialContent != null ? initialContent : 'Your text here...',
			editable: true,
			editorProps: {
				attributes: {
					class: 'focus:outline-none py-2 text-brand-gray-3 prose prose-sm max-w-none',
				},
			},
			onUpdate: ({ editor }) => {
				handleEditorChange(editor)
			},
		})
	})

	onDestroy(() => {
		if (editor) {
			editor.destroy()
		}
	})

	function toggleBold() {
		editor?.chain().focus().toggleBold().run()
	}
	function toggleItalic() {
		editor?.chain().focus().toggleItalic().run()
	}
	function toggleStrike() {
		editor?.chain().focus().toggleStrike().run()
	}
	function toggleBulletList() {
		editor?.chain().focus().toggleBulletList().run()
	}
	function toggleOrderedList() {
		editor?.chain().focus().toggleOrderedList().run()
	}
	function toggleUnderline() {
		editor?.chain().focus().toggleUnderline().run()
	}

	let isActive = $derived((type: string, attrs = {}) => editor?.isActive(type, attrs) ?? false)
</script>

{#if !browser}
	<div class="w-full space-y-2 mt-4 pb-2">
		<SkeletonBlock width="100%" height="16px" />
		<SkeletonBlock width="90%" height="16px" />
		<SkeletonBlock width="95%" height="16px" />
	</div>
{:else}
	<div class="flex flex-wrap gap-2 mt-2">
		<SleekButton
			class={isActive('bold') ? 'bg-brand-gray-2 transition-colors' : ''}
			onclick={toggleBold}>B</SleekButton
		>
		<SleekButton
			class={isActive('italic') ? 'bg-brand-gray-2 transition-colors' : ''}
			onclick={toggleItalic}><em>I</em></SleekButton
		>
		<SleekButton
			class={isActive('strike') ? 'bg-brand-gray-2 transition-colors' : ''}
			onclick={toggleStrike}><s>S</s></SleekButton
		>
		<SleekButton
			class={isActive('underline') ? 'bg-brand-gray-2 transition-colors' : ''}
			onclick={toggleUnderline}><u>U</u></SleekButton
		>

		<div class="w-px h-6 bg-gray-300 dark:bg-brand-gray-4 mx-2 self-center"></div>

		<SleekButton
			class={isActive('bulletList') ? 'bg-brand-gray-2 transition-colors' : ''}
			onclick={toggleBulletList}>•</SleekButton
		>
		<SleekButton
			class={isActive('orderedList') ? 'bg-brand-gray-2 transition-colors' : ''}
			onclick={toggleOrderedList}>1.</SleekButton
		>
	</div>

	<div
		bind:this={element}
		class="w-full min-h-[100dvh+600px] sm:min-h-[100px] mt-2 border p-2 rounded cursor-text dark:border-brand-gray-4 dark:text-brand-gray-2 focus:outline-none"
	></div>

	<!-- Error message -->

	<div class="h-4">
		{#if error}
			<span class="text-red-500 text-xs mt-2">{error}</span>
		{/if}
	</div>
{/if}

<style>
	:global(.ProseMirror) {
		outline: none;
	}

	:global(.ProseMirror p.is-editor-empty:first-child::before) {
		color: #adb5bd;
		content: attr(data-placeholder);
		float: left;
		height: 0;
		pointer-events: none;
	}

	:global(.ProseMirror pre) {
		background: #0d0d0d;
		border-radius: 0.5rem;
	}

	:global(.ProseMirror strong) {
		color: inherit;
	}
</style>
